<template>
  <v-card
    flat
    class="ma-3 pa-6"
  >
    <v-row align="center" class="pl-0 mb-6 pt-3 pr-3">
      <v-card-title>
        Artikelen
      </v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
        outlined
        dense
        v-model="filter.query"
        placeholder="Zoek..."
        append-icon="fas fa-search"
        single-line
        hide-details
        @keyup="searchKeyUp()"
      ></v-text-field>
      <v-btn
        class="ml-4"
        depressed
        color="primary"
        @click="newClick"
      >
    <v-icon
      small
      left
    >fas fa-folder-plus</v-icon>
    Nieuw
    </v-btn>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="totalCount"
      :footer-props="{ 'items-per-page-options': [5, 10, 50] }"
      :loading="isSearching"
      @click:row="rowClick"
    >

      <template v-slot:item.price="{ item }">
        {{ formatCurrency(item.price) }}
      </template>

      <template v-slot:item.is_stock_item="{ item }">
        <v-icon :color="item.is_stock_item ? 'green' : 'grey'" x-small>fas fa-circle</v-icon>
      </template>

      <template v-slot:item.vat.percentage="{ item }">
        {{ formatPercentage(item.vat.percentage) }}
      </template>

      <template v-slot:item.action="{ item }">
        <v-icon
          v-if="canDeleteProduct(item)"
          small
          @click.stop="$refs.confirmDelete.show(item.id)"
        >fas fa-trash</v-icon>
      </template>

    </v-data-table>

    <ConfirmDialog
      ref="confirmDelete"
      title="Verwijderen"
      labelConfirm="Verwijderen"
      labelConfirmColor="error"
      @confirm="deleteClick"
    ></ConfirmDialog>


    <v-dialog
      v-model="dialog"
      max-width="700"
      persistent
    >
      <v-card>
        <v-toolbar color="title" dark flat short class="px-2">
          <v-toolbar-title>Artikel</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false" icon><v-icon>fas fa-times</v-icon></v-btn>
        </v-toolbar>

        <v-card-text class="mt-12">
          <v-form @submit.prevent>
            <v-text-field
              outlined
              class="mb-2"
              v-model="model.name"
              :error-messages="errors.name"
              label="Omschrijving"
            ></v-text-field>

            <v-select
              outlined
              class="mb-2"
              :items="productGroups"
              item-text="name"
              item-value="id"
              v-model="model.product_group_id"
              :error-messages="errors.product_group_id"
              label="Artikelgroep"
            ></v-select>

            <v-select
              outlined
              class="mb-2"
              :items="vats"
              item-text="description"
              item-value="id"
              v-model="model.vat_id"
              :error-messages="errors.vat_id"
              label="Btw"
            ></v-select>


            <NumberField
              outlined
              class="mb-2"
              v-model="model.price"
              :error-messages="errors.price"
              label="Prijs"
              prefix="€"
            ></NumberField>

            <v-text-field
              outlined
              class="mb-2"
              v-model="model.unit"
              :error-messages="errors.unit"
              label="Eenheid"
            ></v-text-field>

            <v-select
              outlined
              class="mb-2"
              :items="suppliers"
              item-text="company_name"
              item-value="id"
              clearable
              v-model="model.default_supplier_id"
              :error-messages="errors.default_supplier_id"
              label="Voorkeursleverancier"
            ></v-select>

            <v-checkbox
              v-model="model.is_stock_item"
              :error-messages="errors.is_stock_item"
              label="Inkoopartikel"
              :false-value="0"
              :true-value="1"
            ></v-checkbox>

          </v-form>
        </v-card-text>

        <v-card-actions class="px-6 pb-8">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="px-5"
            large
            outlined
            depressed
            @click="dialog = false"
          >Annuleren</v-btn>
          <v-btn
            color="primary"
            class="px-5"
            large
            depressed
            @click="saveClick()"
          >Opslaan</v-btn>
        </v-card-actions>


      </v-card>





    </v-dialog>

  </v-card>
  
</template>

<script>
import { inject, ref } from '@vue/composition-api'
import { debounce } from 'lodash'
import ConfirmDialog from '@/components/ConfirmDialog'
import NumberField from '@/components/NumberField'
import { formatCurrency, formatPercentage } from '@/utils'

export default {
  components: {
    ConfirmDialog,
    NumberField,
  },
  setup() {
    const api = inject('api')
    const loader = inject('loader')

    const productGroups = api.resource('product-groups').collection().all()
    const vats = api.resource('vats').collection().all()
    const suppliers = api.resource('suppliers').collection().all({ sort: 'company_name' })

    const productCollection = api.resource('products').collection({
      params: {
        expand: 'product_group,default_supplier,vat',
      },
      rules: {
        query: 'query:name',
      }
    })

    const { items, filter, totalCount, options, isSearching } = productCollection

    const headers = [
      { text: 'Name', value: 'name' },
      { text: 'Artikelgroep', value: 'product_group.name' },
      { text: 'Prijs', value: 'price' },
      { text: 'Eenheid', value: 'unit' },
      { text: 'Inkoopartikel', value: 'is_stock_item', align: 'center' },
      { text: 'Btw', value: 'vat.percentage' },
      { text: '', value: 'action', align: 'center' },
    ]

    const dialog = ref(false)

    const productModel = api.resource('products').model()
    const { attributes: model, errors, isBusy } = productModel

    loader.bindRef(isBusy)

    function newClick() {
      productModel.clear()
      dialog.value = true
    }

    function rowClick(item) {
      productModel.find(item.id).then(() => {
        dialog.value = true
      })
    }

    function saveClick() {
      productModel.save().then(() => {
        productCollection.search()
        dialog.value = false
      })
    }

    function deleteClick(id) {
      api.resource('products').remove(id).then(() => {
        productCollection.search()
      })
    }

    function canDeleteProduct(product) {
      return ![1].includes(product.id)
    }

    const searchKeyUp = debounce(productCollection.search, 300)

    return {
      api,
      headers,
      items,
      filter,
      searchKeyUp,
      totalCount,
      options,
      isSearching,
      newClick,
      rowClick,
      saveClick,
      deleteClick,
      dialog,
      model,
      errors,
      formatCurrency,
      formatPercentage,
      productGroups,
      vats,
      suppliers,
      canDeleteProduct,
    }

  },
}
</script>


<style scoped>

::v-deep .v-data-table table tbody tr:not(.v-data-table__empty-wrapper):hover {
  cursor: pointer;
}

</style>
