<template>
  <v-card
    flat
    class="ma-3 pa-6"
  >
    <v-row align="center" class="pl-0 mb-6 pt-3 pr-3">
      <v-card-title>
        Inplannen
      </v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
        outlined
        dense
        v-model="filter.query"
        label="Zoek..."
        single-line
        hide-details
        clearable
        clear-icon="fa-times"
        @keydown.enter="search()"
        @click:clear="search()"
      ></v-text-field>
      <v-text-field
        outlined
        dense
        v-model="filter.geo_address"
        label="Adres"
        single-line
        hide-details
        class="ml-3"
        clearable
        clear-icon="fa-times"
        @keydown.enter="search()"
        @click:clear="search()"
      ></v-text-field>
    </v-row>

    <v-alert
      v-if="hasGeoError"
      dense
      type="warning"
    >Niet alle klanten hebben een geolocatie</v-alert>

    <v-expansion-panels
      accordion
      flat
      multiple
      class="mb-8"
      v-model="panels"
    >

      <v-expansion-panel
        flat
        v-for="(offer, index) in offers"
        :key="'offer_'+index"
      >

        <v-expansion-panel-header>
          <v-row>
            <v-col cols="11">
              <span><router-link :to="{ name: 'offer', params: { id: offer.id } }" target="_blank">{{ offer.offer_number }}</router-link> - {{ offer.customer.name }}</span>
              <span v-if="offer.customer.address || offer.customer.city">, {{ offer.customer.address }} {{ offer.customer.city }}</span>
              <span v-if="offer.customer.phone_number">, {{ offer.customer.phone_number }}</span>
              <span v-if="offer.customer.geo_distance_km">, {{ formatNumber(offer.customer.geo_distance_km, 2) }} km</span>
              <span v-if="hasGeoResult && offer.customer && offer.customer.geo_distance_km === null">&nbsp;<v-icon small class="red--text">fas fa-exclamation</v-icon></span>
            </v-col>
            <v-col cols="1">
              <v-chip
                small
                :color="offer.totals.to_be_received == 0 ? 'success' : 'default'"
              >{{ offer.totals.received }} / {{ offer.totals.total }}</v-chip>
            </v-col>
          </v-row>
        </v-expansion-panel-header>

        <v-expansion-panel-content>

          <v-data-table
            v-model="selected[offer.id]"
            show-select
            :headers="headers"
            :items="offer.offer_lines"
            :loading="isSearching"
            disable-pagination
            disable-sort
            disable-filtering
            hide-default-footer
            selectable-key="is_received"
          >

            <template v-slot:item.offer_line_status_id="{ item }">
              <v-chip
                small
                dark
                :color="getOfferLineStatusColor(item.offer_line_status_id)"
              >{{ item.offer_line_status.name }}</v-chip>
            </template>

            <template v-slot:item.assembly_date="{ item }">
              {{ formatDate(item.assembly_date) }}
            </template>

            <template v-slot:item.unit_price="{ item }">
              {{ formatCurrency(item.unit_price) }}
            </template>

            <template v-slot:item.price="{ item }">
              {{ formatCurrency(item.price) }}
            </template>

          </v-data-table>

          <v-btn
            color="primary"
            class="mt-6"
            depressed
            dense
            :disabled="!selected[offer.id].length"
            @click="assemblyClick(offer)"
          >
            <v-icon small left>fas fa-truck</v-icon>
            Inplannen
          </v-btn>

        </v-expansion-panel-content>
      </v-expansion-panel>

    </v-expansion-panels>


    <v-dialog
      v-model="assemblyDialog"
      max-width="700"
      persistent
    >
      <v-card>
        <v-toolbar color="title" dark flat short class="px-2">
          <v-toolbar-title>Inplannen</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="assemblyDialog = false" icon><v-icon>fas fa-times</v-icon></v-btn>
        </v-toolbar>

        <v-card-text class="mt-12">
          <p>{{ offerLines.length }} offerteregel(s) geselecteerd</p>

          <v-form
            ref="assemblyForm"
            @submit.prevent
          >

            <date-picker
              v-model="assemblyDate"
              outlined
              label="Montage datum"
              :rules="[value => !!value || 'Montage datum is verplicht', value => /^\d{2}-\d{2}-\d{4}/.test(value) || 'Montage datum heeft een ongeldig formaat']"
            ></date-picker>

            <v-textarea
              outlined
              v-model="offer.assembly_remarks"
              label="Montage opmerking"
            ></v-textarea>

          </v-form>


        </v-card-text>

        <v-card-actions class="px-6 pb-8">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="px-5"
            large
            outlined
            depressed
            @click="assemblyCancel()"
          >Annuleren</v-btn>
          <v-btn
            color="primary"
            class="px-5"
            large
            depressed
            @click="assemblySave()"
          >Inplannen</v-btn>
        </v-card-actions>


      </v-card>

    </v-dialog>

  </v-card>
  
</template>

<script>
import Vue from 'vue'
import { inject, computed, ref, reactive } from '@vue/composition-api'
import { debounce } from 'lodash'
import DatePicker from '@/components/DatePicker'
import { formatCurrency, formatDate, formatNumber, getOfferLineStatusColor, OFFER_STATUS_ORDERED, OFFER_LINE_STATUS_RECEIVED, OFFER_LINE_STATUS_SCHEDULED } from '@/utils'

export default {
  components: {
    DatePicker,
  },
  setup(props, context) {
    const api = inject('api')

    const offerCollection = api.resource('offers').collection({
      params: {
        expand: 'offer_lines,customer,offer_lines.supplier,offer_lines.offer_line_status,totals',
        filter: {
          offer_status_id: { eq: OFFER_STATUS_ORDERED },
          offer_line_status_id: { eq: OFFER_LINE_STATUS_RECEIVED },
        },
      },
      rules: {
        query: 'query:contact_name,company_name',
        geo_address: 'eq',
      },
    })

    offerCollection.all()

    const { filter, isSearching, search } = offerCollection
    const selected = reactive({})
    const selectReady = ref(1)
    const panels = ref([])

    const hasGeoResult = ref(false)
    const hasGeoError = ref(false)

    const offers = computed(() => {

      let _hasGeoResult = false
      let _hasGeoError = false

      offerCollection.forEach(offer => {
        Vue.set(selected, offer.id, [])
        if (offer.customer.geo_distance_km) _hasGeoResult = true
        if (offer.customer.geo_distance_km === null) _hasGeoError = true
      })

      hasGeoResult.value = _hasGeoResult
      hasGeoError.value = _hasGeoResult && _hasGeoError

      const offers = offerCollection.state.items

      offers.sort((a, b) => {
        if (a.customer.geo_distance_km === b.customer.geo_distance_km) return 0
        if (a.customer.geo_distance_km === null) return 1
        if (b.customer.geo_distance_km === null) return -1
        return a.customer.geo_distance_km - b.customer.geo_distance_km
      })

      // const offers = offerCollection.state.items.filter(item => selectReady.value
      //   ? item.totals.to_be_received == 0
      //   : item.totals.to_be_received > 0)

      return offers
    })

    const headers = [
      { text: 'Status', value: 'offer_line_status_id', sortable: false },
      { text: 'Montage datum', value: 'assembly_date', sortable: false },
      { text: 'Omschrijving', value: 'description', sortable: false },
      { text: 'Leverancier', value: 'supplier.company_name', sortable: false },
      { text: 'Aantal', value: 'quantity', sortable: false },
      { text: 'Stuksprijs', value: 'unit_price', sortable: false },
      { text: 'Prijs', value: 'price', sortable: false },
    ]

    const assemblyDialog = ref(false)

    const offerModel = api.resource('offers').model()
    const offerLineCollection = api.resource('offer-lines').collection()
    const offerLineModel = api.resource('offer-lines').model()
    const assemblyDate = ref()

    const { attributes: offer } = offerModel
    const { items: offerLines } = offerLineCollection



    function assemblyClick(offer) {
      offerModel.populate(offer)
      offerLineCollection.populate(selected[offer.id])
      assemblyDate.value = null
      assemblyDialog.value = true
    }

    function assemblySave() {
      if (context.refs.assemblyForm.validate()) {

        const models = [offerModel.save()]

        offerLineCollection.forEach(offerLine => {
          offerLineModel.clear()
          offerLineModel.populate(offerLine)
          offerLineModel.setAttributes({
            offer_line_status_id: OFFER_LINE_STATUS_SCHEDULED,
            assembly_date: assemblyDate.value,
          })
          models.push(offerLineModel.save())
        })
        Promise.all(models).then(() => {
          offerCollection.all()
          assemblyDialog.value = false
          panels.value = []
          context.refs.assemblyForm.reset()
        })
      }
    }

    function assemblyCancel() {
      assemblyDialog.value = false
      context.refs.assemblyForm.reset()
    }

   
    const searchKeyUp = debounce(offerCollection.search, 300)

    return {
      panels,
      selectReady,
      selected,
      headers,
      offers,
      filter,
      search,
      isSearching,
      assemblyDialog,
      assemblyClick,
      assemblySave,
      assemblyCancel,
      searchKeyUp,
      formatCurrency,
      formatNumber,
      offer,
      offerLines,
      assemblyDate,
      getOfferLineStatusColor,
      formatDate,
      hasGeoResult,
      hasGeoError,
    }

  },
}
</script>


<style scoped>

::v-deep .v-data-table table tbody tr:not(.v-data-table__empty-wrapper):hover {
  cursor: pointer;
}


</style>
